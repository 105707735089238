<template>
  <div v-if="showBanner" id="cookie-notification">
    <div id="cookie-notification-inner" style="font-style: normal!important;">
      <div id="cookie-message">
        <h3 style="font-weight: 900;font-style: normal!important;">AVISO DE COOKIES</h3>
        <p class="mb-0" style="font-size: 1.3em;font-style: normal!important;">
          Utilizamos cookies propias y de terceros para mejorar nuestros servicios. Si continúa con la navegación, consideraremos que acepta este uso.
        </p>
      </div>
      <div id="cookie-notification-buttons">
        <button id="cookie-button" @click="acknowledgeConsent" style="font-weight: 900;font-style: normal!important;" >Aceptar</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showBanner: false, // Controls visibility of the banner
    };
  },
  mounted() {
    // Check if the user has acknowledged cookies
    const acknowledged = localStorage.getItem('cookiesAcknowledged');
    if (!acknowledged) {
      // Show banner if the user hasn't acknowledged the cookies
      this.showBanner = true;
    }
  },
  methods: {
    acknowledgeConsent() {
      // Store acknowledgement in localStorage
      localStorage.setItem('cookiesAcknowledged', 'true');
      this.hideBanner();
    },
    hideBanner() {
      // Hide the banner
      this.showBanner = false;
    }
  }
};
</script>

<style scoped>
#cookie-notification {
  background: rgba(255, 255, 255, 0.98); /* White background with slight transparency */
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10000;
  padding: 20px;
  text-align: center;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

#cookie-notification-inner {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#cookie-message {
  font-size: 14px;
  color: #333;
  margin-bottom: 15px;
}

#cookie-notification-buttons button {
  background-color: #f44336; /* Red button */
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
}

#cookie-notification-buttons button:hover {
  opacity: 0.9;
}

#cookie-button:hover {
  transition: transform 0.1s ease-in-out;
  transform: scale(1.07);
}
</style>
